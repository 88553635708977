
    import {computed, defineComponent, onMounted, reactive, toRefs, ref} from 'vue';
    import ApiUser from "@/request/apis/api_user";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import UploadUtil from "@/utils/upload_util";
    import {baseUrl} from "@/utils/config";
    interface IState {
        searches: any,
        total: any,
        list: any,
        visible: any,
        form: any,
        title: any,
        name: any,
        type: any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    name: ""
                },
                total: 0,
                list: [],
                activeKey: 1,
                visible: false,
                name: '',
                title: '',
                form: {
                    is_show: 1,
                    name: '',
                    image: '',
                    remark: '',
                    sort: 255,
                    initial: undefined
                },
                type: '',
            })
            const rules = {
                name: [{required: true, message: "请输入品牌名称"},],
                sort: [{required: true, message: "请输入排序"},],
                is_show: [{required: true, message: "请选择是否显示"},],
                image: [{required: true, message: "请上传品牌logo"},],
                initial: [{required: true, message: "请选择品牌首字母"},],
                remark: [{required: true, message: "请输入品牌简介"},],
            }
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center',
                    width: 100
                },
                {
                    title: '品牌logo',
                    dataIndex: 'image',
                    key: 'image',
                    align: 'center',
                },
                {
                    title: '品牌名称',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                },
                {
                    title: '品牌首字母',
                    dataIndex: 'initial',
                    key: 'initial',
                    align: 'center',
                },
                {
                    title: '排序',
                    dataIndex: 'sort',
                    key: 'sort',
                    align: 'center',
                },
                {
                    title: '显示状态',
                    dataIndex: 'is_show',
                    key: 'is_show',
                    align: 'center',
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 250,
                    align: 'center',
                },
            ];

            const getList = async () => {
                const params = {...state.searches}
                const res = await ApiUser.brandLists(params)
                if (res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.lists
                    state.total = data.count
                }
            }

            const onDel = async (val) => {
                const params = {id: val.id}
                const res = await ApiUser.brandDel(params)
                if (res.error_code == 0) {
                    message.success(res.msg)
                    getList()
                } else {
                    message.error(res.msg)
                }
            }

            const edit = (val) => {
                state.title = '编辑商品品牌'
                state.form.name = val.name
                state.form.sort = val.sort
                state.form.remark = val.remark
                state.form.image = val.image
                state.form.is_show = val.is_show
                state.form.initial = val.initial
                state.form.id = val.id
                state.type = 2
                state.visible = true
            }
            const Words = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

            const formRef = ref();
            const handleOk = async () => {
                formRef.value.validate().then(async () => {
                    const params = {...state.form}
                    if (state.type == 1) {
                        const res = await ApiUser.brandAdd(params)
                        if (res.error_code == 0) {
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    } else {
                        const res = await ApiUser.brandEdit(params)
                        if (res.error_code == 0) {
                            message.success(res.msg)
                            state.visible = false
                            getList()
                        } else {
                            message.error(res.msg)
                        }
                    }
                })
                    .catch((error: any) => {
                        console.log("error", error)
                    })
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const add = (val) => {
                state.visible = true
                state.type = 1
                state.form = {
                    is_show: 1,
                    name: '',
                    image: '',
                    remark: '',
                    sort: 255,
                    initial: undefined
                },
                    state.title = '新增商品品牌'
            }

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };
            const uploadHeaders = UploadUtil.uploadHeaders()
            let uploadAction = baseUrl + '/admin/upload/image'
            let uploadFormData = {cid: 10}
            const handleChange2 = ({file}: any) => {
                if (file.status === 'done' && file.response) {
                    const res = file.response
                    if (res && res.error_code === 0) {
                        const data = res.data
                        state.form.image = data.uri
                    }
                }
            }
            const onSearch = (key: string, value: any, e: any) => {
                state.searches.page = 1
                state.searches.limit = PageUtil.pageSize
                getList()
            }
            const onReset = (key: string, value: any, e: any) => {
                state.searches.page = PageUtil.pageSize
                state.searches.limit = PageUtil.pageSize
                state.searches.name = ''
                getList()
            }

            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                getList,
                uploadHeaders,
                onReset,
                uploadAction,
                handleChange2,
                pagination,
                onSearch,
                uploadFormData,
                handleTableChange,
                formRef,
                handleOk,
                edit,
                add,
                rules,
                columns,
                Words,
                onDel
            }
        }
    });
